exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-are-evs-really-green-js": () => import("./../../../src/pages/are-evs-really-green.js" /* webpackChunkName: "component---src-pages-are-evs-really-green-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog/post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-calculator-old-js": () => import("./../../../src/pages/calculator_old.js" /* webpackChunkName: "component---src-pages-calculator-old-js" */),
  "component---src-pages-imagine-if-your-power-company-paid-you-js": () => import("./../../../src/pages/imagine-if-your-power-company-paid-you.js" /* webpackChunkName: "component---src-pages-imagine-if-your-power-company-paid-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-we-are-in-the-age-of-solar-js": () => import("./../../../src/pages/we-are-in-the-age-of-solar.js" /* webpackChunkName: "component---src-pages-we-are-in-the-age-of-solar-js" */)
}

